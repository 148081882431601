import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {VerificationService} from '../services/verification.service';
import {FormService} from '../services/form.service';
import {Color} from '../FormElements/dynamic-form/form.component';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss'],
})
export class ValidationComponent implements OnInit {

  link: string = null;
  idForm: string = null;
  bgColorPalette: Color[] = [];
  bg = null;

  constructor(private route: ActivatedRoute, private FormService: FormService, private VerificationService: VerificationService, private router: Router) {
  }

  public ngOnInit(): void {

    this.idForm = this.router.url.replace('/validation/', '');
    var domainName = document.referrer.split('/')[2] || window.location.hostname || document.location.hostname;
    this.FormService.getFormData(this.idForm, {referer: document.referrer}).subscribe((data: any[]) => {
      this.bg = data[0].formBackgroundColor;
      document.documentElement.style.setProperty('--bgColor', this.bg);

      // Gestion du texte
      document.getElementById('ValidationText').innerText = data[0].formValidationText;

      // Gestion du font family
      let FontFamily;
      FontFamily = document.createElement('link');
      FontFamily.setAttribute('rel', 'stylesheet');
      FontFamily.setAttribute('href', this.getFontCSS(data[0].formSecondFont));
      document.head.appendChild(FontFamily);
      document.documentElement.style.setProperty('--secondFont', data[0].formSecondFont);

    }, error => {
      console.log(error);
    }, () => {
      this.setBgColor();

      // Gestion du background
      if (this.pickTextColorBasedOnBgColorAdvanced(this.bg) == 'white') {
        var choice = [400, 300, 200, 100, -50, -100, -200, -300, -400, -500, -600, -700];
        this.checkContrast(this.bg, choice);
      } else {
        var choice = [1700, 1600, 1500, 1400, 1300, 1200, 1100, 1000, 900, 800, 700, 600];
        this.checkContrast(this.bg, choice);
      }
    });
  }

  private getFontCSS(name) {
    this.link = `https://fonts.googleapis.com/css?family=${name.replace(' ', '+')}&display=swap`;
    return this.link;
  }

  public hexToRgb(hex) {
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  public luminance(r, g, b) {
    var a = [r, g, b].map(function(v) {
      v /= 255;
      return v <= 0.03928
        ? v / 12.92
        : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  public pickTextColorBasedOnBgColorAdvanced(bgColor) {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
    return (L > 0.179) ? 'black' : 'white';
  }

  public checkContrast(color, choice) {
    var luminanceBg = this.luminance(this.hexToRgb(color).r, this.hexToRgb(color).g, this.hexToRgb(color).b);
    for (var i = 0; i < choice.length; i++) {
      var luminancetext = this.luminance(this.hexToRgb(document.documentElement.style.getPropertyValue('--theme-bg-' + choice[i])).r,
        this.hexToRgb(document.documentElement.style.getPropertyValue('--theme-bg-' + choice[i])).g,
        this.hexToRgb(document.documentElement.style.getPropertyValue('--theme-bg-' + choice[i])).b);
      const ratio = luminanceBg > luminancetext
        ? ((luminancetext + 0.05) / (luminanceBg + 0.05))
        : ((luminanceBg + 0.05) / (luminancetext + 0.05));
      if (ratio < 1 / 4.5) {
        document.documentElement.style.setProperty('--PlaceHolder', document.documentElement.style.getPropertyValue('--theme-bg-' + choice[i]));
        return;
      }
    }
  }

  private LightenDarkenColor(col, amt) {

    // Suppression du #
    col = col.slice(1);

    const num = parseInt(col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }

    let g = ((num >> 8) & 0x00FF) + amt;

    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }

    let b = (num & 0x0000FF) + amt;

    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }

    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  private getColorObject(value, name): Color {
    return {
      name,
      hex: value,
    };
  }

  private computeColors(hex: string): Color[] {
    return [
      this.getColorObject(this.LightenDarkenColor(hex, 255), '-700'),
      this.getColorObject(this.LightenDarkenColor(hex, 220), '-600'),
      this.getColorObject(this.LightenDarkenColor(hex, 200), '-500'),
      this.getColorObject(this.LightenDarkenColor(hex, 180), '-400'),
      this.getColorObject(this.LightenDarkenColor(hex, 160), '-300'),
      this.getColorObject(this.LightenDarkenColor(hex, 140), '-200'),
      this.getColorObject(this.LightenDarkenColor(hex, 90), '-100'),
      this.getColorObject(this.LightenDarkenColor(hex, 70), '-50'),
      this.getColorObject(this.LightenDarkenColor(hex, 52), '50'),
      this.getColorObject(this.LightenDarkenColor(hex, 37), '100'),
      this.getColorObject(this.LightenDarkenColor(hex, 26), '200'),
      this.getColorObject(this.LightenDarkenColor(hex, 12), '300'),
      this.getColorObject(this.LightenDarkenColor(hex, 6), '400'),
      this.getColorObject(this.LightenDarkenColor(hex, 0), '500'),
      this.getColorObject(this.LightenDarkenColor(hex, -12), '600'),
      this.getColorObject(this.LightenDarkenColor(hex, -18), '700'),
      this.getColorObject(this.LightenDarkenColor(hex, -24), '800'),
      this.getColorObject(this.LightenDarkenColor(hex, -52), '900'),
      this.getColorObject(this.LightenDarkenColor(hex, -70), '1000'),
      this.getColorObject(this.LightenDarkenColor(hex, -90), '1100'),
      this.getColorObject(this.LightenDarkenColor(hex, -140), '1200'),
      this.getColorObject(this.LightenDarkenColor(hex, -160), '1300'),
      this.getColorObject(this.LightenDarkenColor(hex, -180), '1400'),
      this.getColorObject(this.LightenDarkenColor(hex, -200), '1500'),
      this.getColorObject(this.LightenDarkenColor(hex, -220), '1600'),
      this.getColorObject(this.LightenDarkenColor(hex, -255), '1700'),
    ];
  }

  private setBgColor() {
    this.bgColorPalette = this.computeColors(this.bg);
    for (const color of this.bgColorPalette) {
      const key = `--theme-bg-${color.name}`;
      const value = color.hex;
      document.documentElement.style.setProperty(key, value);
    }
  }
}
