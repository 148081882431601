import {Component, ViewChild} from '@angular/core';
import {FormGroup, ValidatorFn} from '@angular/forms';
import {FieldsConfig} from '../../field.interface';
import {AutoCompletionService} from '../../services/auto-completion.service';
import {FormService} from '../../services/form.service';
import {MatAutocompleteTrigger} from '@angular/material';
import {takeUntil} from 'rxjs/operators';
import {timer} from 'rxjs';
import {VerificationService} from '../../services/verification.service';
import {FormComponent} from '../dynamic-form/form.component';
import { faCoffee,faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-auto-completion',
  templateUrl: './auto-completion.component.html',
  styleUrls: ['./auto-completion.component.scss'],
})
export class AutoCompletionComponent {

  public field: FieldsConfig;
  public group: FormGroup;
  public randomId: string;
  public id: number;
  public isLoading: boolean;
  public subscription = [];
  public AutoComplete$ = [];
  public timer: any;
  public error3s = false;
  public static token = null;

  constructor(private autoCompletionService: AutoCompletionService, private formService: FormService, private VerificationService: VerificationService) {
  }

  public change($event) {
    if ($event != '') {
      this.error3s = false;

      if (typeof $event != 'object') {
        if (this.timer == null) {
          this.changeMethod($event);
        } else {
          clearTimeout(this.timer);
          this.changeMethod($event);
        }
      } else {
        this.AutoComplete$ = [];
        if (!this.VerificationService.editionMode) {
          const result = {};

          result['id'] = this.id;
          result['randomId'] = this.randomId;
          result['fields'] = [];
          result['fields'].push({
            key: this.field.idFormField,
            value: this.group.get(this.field.idFormField.toString()).value,
          });

          if (AutoCompletionComponent.token != null) {
            result['token'] = AutoCompletionComponent.token;
          } else {
            result['token'] = null;
          }

          try {
            this.formService.insertAutoCompletion(result).subscribe(
              (res) => {
                if (res['code'] == 200 && res['token'] != null) {
                  AutoCompletionComponent.token = res['token'];
                }
              },
              (err) => {
                console.log(err);
              },
            );

          } catch (err) {
            console.log(err);
          }
        }
      }
    }
  }

  public cancelSub(subscription: any[]) {
    for (let i = 0; i < subscription.length; i++) {
      this.subscription[i].unsubscribe();
    }
  }

  public changeMethod($event) {
    if (!this.group.get(this.field.idFormField.toString()).errors && !this.group.get(this.field.idFormField.toString()).hasError('required')) {
      /*Objet retourné*/
      const result = {};

      result['id'] = this.id;
      result['fields'] = [];
      result['fields'].push({
        key: this.field.idFormField,
        value: this.group.get(this.field.idFormField.toString()).value,
      });

      if (AutoCompletionComponent.token != null) {
        result['token'] = AutoCompletionComponent.token;
      } else {
        result['token'] = null;
      }

      try {
        this.formService.insertAutoCompletion(result).subscribe(
          (res) => {
            if (res['code'] == 200 && res['token'] != null) {
              AutoCompletionComponent.token = res['token'];
            }
          },
          (err) => {
            console.log(err);
          },
        );
      } catch (err) {
        console.log(err);
      }
    }

    this.error3s = false;
    this.timer = setTimeout((newValidator: ValidatorFn | ValidatorFn[] | null) => {
      this.cancelSub(this.subscription);
      this.isLoading = true;
      this.AutoComplete$ = [];
      const saisie = $event;

      var request = this.autoCompletionService.getFormData(saisie);

      var a = request.subscribe((res) => {
          for(let i = 0; i< Object.keys(res).length;i++){
            this.AutoComplete$.push(res[i]);
          }
      }, (err) => {
        console.log(err);
      }, () => {
        this.isLoading = false;
        this.error3s = false;
      });
      this.subscription.push(a);
      var code_postal = /((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B))[0-9]{3}/;
      if(!code_postal.test(saisie)){
        timer(3000).pipe(takeUntil(request)).subscribe(next => {
          this.error3s = true;
        }, err => {
          console.log(err);
        });
      }
    }, 750);
  }

  public displayFn(org): string {
    return org ? org.companyName : org;
  }
}
