import {HttpClient} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "./../../environments/environment";

@Injectable()
export class AutoCompletionService {

  public url: string;
  constructor(private http: HttpClient) {
    this.url = environment.server;
  }

  public getFormData(q) {
    return this.http.get(`${this.url}/autocomplete/` + q);
  }


}
