import {HostListener, Directive, ElementRef, DoCheck, Output, EventEmitter} from '@angular/core';

@Directive({selector: '[myDir]'})
export class HostDirective implements DoCheck{

  @Output() private height = new EventEmitter();
  constructor(private el:  ElementRef){
  }
  ngDoCheck(){
    this.height.emit(this.el.nativeElement.querySelector('.global').offsetHeight);
  }
}
