import {CommonModule} from "@angular/common";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {NgModule} from "@angular/core";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RouterModule, Routes} from "@angular/router";
import {OWL_DATE_TIME_LOCALE, OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule} from "ng-pick-datetime";
import {OwlMomentDateTimeModule} from "ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time.module";
import {AppComponent} from "./app.component";
import {CheckBoxComponent} from "./FormElements/checkbox/check-box.component";
import {DynamicFieldDirective} from "./FormElements/dynamic-field/dynamic-field.directive";
import {FormComponent} from './FormElements/dynamic-form/form.component';
import {InputDateComponent} from "./FormElements/input-date/input-date.component";
import {InputDateTimeComponent} from "./FormElements/input-datetime/input-datetime.component";
import {InputNumberComponent} from "./FormElements/input-number/input-number.component";
import {InputStringLongComponent} from "./FormElements/input-string-long/input-string-long.component";
import {InputStringShortComponent} from "./FormElements/input-string-short/input-string-short.component";
import {RadioButtonComponent} from "./FormElements/radio/radio-button.component";
import {LoaderInterceptor} from "./interceptors/loader.interceptor";
import {LoaderComponent} from "./loader/loader.component";
import {MaterialModule} from "./material.module";
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import {FormService} from "./services/form.service";
import {LoaderService} from "./services/loader.service";
import {ValidationComponent} from "./validation/validation.component";

import {TextMaskModule} from "angular2-text-mask";
import {AutoCompletionComponent} from "./FormElements/auto-completion/auto-completion.component";
import {InputMailComponent} from "./FormElements/input-mail/input-mail.component";
import {InputTelComponent} from "./FormElements/input-tel/input-tel.component";
import {AutoCompletionService} from "./services/auto-completion.service";
import {VerificationService} from './services/verification.service';
import {HostDirective} from './FormElements/dynamic-form/form.directive';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {InputSelectComponent} from './FormElements/input-select/input-select.component';
import {MatSelectModule} from '@angular/material';
import {EditionValidation} from './FormElements/edition-validation/edition.component';

const routes: Routes = [
  {path: "formulaire/:id", component: FormComponent},
  {path: "validation/:id", component: ValidationComponent},

  {path: "", component: PageNotFoundComponent},
  {path: "**", component: PageNotFoundComponent},
];

@NgModule({
  declarations: [AppComponent,
    ValidationComponent,
    PageNotFoundComponent,
    LoaderComponent,
    FormComponent,
    InputStringShortComponent,
    DynamicFieldDirective,
    HostDirective,
    InputNumberComponent,
    InputDateTimeComponent,
    InputDateComponent,
    InputStringLongComponent,
    RadioButtonComponent,
    CheckBoxComponent,
    AutoCompletionComponent,
    InputMailComponent,
    InputTelComponent,
    InputSelectComponent,
    EditionValidation
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    HttpClientModule,
    MaterialModule,
    MatDatepickerModule,
    MatSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    RouterModule,
    RouterModule.forRoot(
      routes),
    TextMaskModule,
    FontAwesomeModule
  ],
  exports: [
    MaterialModule,
    MatDatepickerModule,
    FormComponent
  ],
  providers: [
    FormService,
    AutoCompletionService,
    LoaderService,
    FormComponent,
    VerificationService,
    {provide: OwlDateTimeIntl, useClass: InputDateTimeComponent},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    {provide: OWL_DATE_TIME_LOCALE, useValue: "fr"},
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    InputStringShortComponent,
    InputNumberComponent,
    InputDateTimeComponent,
    InputDateComponent,
    InputStringLongComponent,
    RadioButtonComponent,
    CheckBoxComponent,
    AutoCompletionComponent,
    FormComponent,
    InputMailComponent,
    InputTelComponent,
    InputSelectComponent,
    EditionValidation],
})
export class AppModule { }
