import {Component} from "@angular/core";
import { FormGroup} from "@angular/forms";
import {FieldsConfig} from "../../field.interface";

@Component({
  selector: "app-input-string-short",
  templateUrl: "./input-string-short.component.html",
  styleUrls: ["./input-string-short.component.scss"],
})
export class InputStringShortComponent {
  public field: FieldsConfig;
  public group: FormGroup;
}
