import {ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef} from "@angular/core";
import { FormGroup} from "@angular/forms";
import {FieldsConfig} from "../../field.interface";
import {AutoCompletionComponent} from "../auto-completion/auto-completion.component";
import {CheckBoxComponent} from "../checkbox/check-box.component";
import {InputDateComponent} from "../input-date/input-date.component";
import {InputDateTimeComponent} from "../input-datetime/input-datetime.component";
import {InputMailComponent} from "../input-mail/input-mail.component";
import {InputNumberComponent} from "../input-number/input-number.component";
import {InputStringLongComponent} from "../input-string-long/input-string-long.component";
import {InputStringShortComponent} from "../input-string-short/input-string-short.component";
import {InputTelComponent} from "../input-tel/input-tel.component";
import {RadioButtonComponent} from "../radio/radio-button.component";
import {InputSelectComponent} from '../input-select/input-select.component';

const componentMapper = {
  1: AutoCompletionComponent,
  2: InputStringShortComponent,
  3: InputStringLongComponent,
  4: InputDateComponent,
  5: InputDateTimeComponent,
  6: InputNumberComponent,
  7: CheckBoxComponent,
  8: RadioButtonComponent,
  9: InputMailComponent,
  10: InputTelComponent,
  11:InputStringShortComponent,
  12:InputStringShortComponent,
  13:InputStringShortComponent,
  14:InputSelectComponent,
  15:InputTelComponent
};

@Directive({
  selector: "[dynamicField]",
})
export class DynamicFieldDirective implements OnInit {

  @Input() public field: FieldsConfig;
  @Input() public group: FormGroup;
  @Input() public id: number;
  @Input() public randomId: string;
  public componentRef: any;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
  ) {}

  public ngOnInit() {
    const factory = this.resolver.resolveComponentFactory(
      componentMapper[this.field.idFieldType],
    );
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.field = this.field;
    this.componentRef.instance.group = this.group;
    this.componentRef.instance.id = this.id;
    this.componentRef.instance.randomId = this.randomId;
  }

}
