import {HttpClient} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "./../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class VerificationService {

  public url: string;
  public editionMode: boolean;

  constructor(private http: HttpClient) {
    this.url = environment.server;
  }

  public verif(data) {
    return this.http.post(`${this.url}/verification`, data);
  }
}
