import {formatDate} from "@angular/common";
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {FormGroup} from "@angular/forms";
import * as moment from "moment";
import * as textMask from "vanilla-text-mask/dist/vanillaTextMask.js";
import {FieldsConfig} from "../../field.interface";
import {MyErrorStateMatcher} from "../input-mail/input-mail.component";

@Component({
  selector: "app-input-date",
  templateUrl: "./input-date.component.html",
  styleUrls: ["./input-date.component.scss"],
})
export class InputDateComponent implements OnInit, AfterViewInit, OnDestroy {

  public field: FieldsConfig;
  public group: FormGroup;
  // mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '']; // dd/mm/yyyy
  // maskedInputController;

  @ViewChild("input", {static: false, read: ViewContainerRef }) public input;

  public matcher = new MyErrorStateMatcher();

  constructor() {}

  public ngAfterViewInit(): void {
   /* this.maskedInputController = textMask.maskInput({
      inputElement: this.input.element.nativeElement,
      mask: this.mask
    });
    console.log(this.mask);*/
  }

  public ngOnDestroy() {
    // this.maskedInputController.destroy();
  }

  public ngOnInit(): void {

  }

}
