import {Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import { FormGroup} from "@angular/forms";
import {FieldsConfig} from "../../field.interface";
import {MyErrorStateMatcher} from "../input-mail/input-mail.component";

@Component({
  selector: "app-input-tel",
  templateUrl: "./input-tel.component.html",
  styleUrls: ["./input-tel.component.scss"],
})
export class InputTelComponent{
  public field: FieldsConfig;
  public group: FormGroup;

  @ViewChild("input", {static: false, read: ViewContainerRef }) public input;

  public matcher = new MyErrorStateMatcher();
}
