// Dev
export const environment = {
  production: false,
  base: 'https://dev.leadform.coefficy.com/formulaire/',
  baseValidation: 'https://dev.leadform.coefficy.com/validation/',
  server: 'https://dev.api.leadform.coefficy.com',
  isAuthorized: false,
  url: 'devapp.maxime.coefficy.com',
  eliott: 'https://dev.eliott.coefficy.com/s/eliott.js'
};
