import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {FormComponent} from '../dynamic-form/form.component';

/**
 * @title Dialog Overview
 */
@Component({
  selector: 'edition-validation',
  templateUrl: 'edition.component.html',
})
export class EditionValidation {

  constructor(
    public dialogRef: MatDialogRef<FormComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
