import {HttpClient} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {Router} from "@angular/router";
import { environment } from "./../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class FormService {
  public url: string;
  public static authorized = false;

  constructor(private http: HttpClient, private router: Router) {
    this.url = environment.server;
  }

  public insert(data, url, token) {
    this.http.post(`${this.url}/`, data)
      .subscribe(
        (res) => {
          if (res['code'] == 200) {
            if (url != null) {
              window.top.location.href = url;
            } else {
              FormService.authorized = true;
              this.router.navigateByUrl("validation/" + token);
            }
          }
        },
        (err) => {
          console.log("Error occurred:" , err);
        },
      );
  }

  public insertAutoCompletion(data) {
    return this.http.post(`${this.url}/autocomplete/`, data);
  }

  public getFormData(idForm,data) {
    return this.http.post(`${this.url}/form/` + idForm, data);
  }

  public check_captcha(data){
    return this.http.post(`${this.url}/captcha` , data);
  }

}
