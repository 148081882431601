import {Component} from "@angular/core";
import { FormGroup} from "@angular/forms";
import {FieldsConfig} from "../../field.interface";

@Component({
  selector: "app-check-box",
  templateUrl: './check-box.component.html',
  styleUrls: ["./check-box.component.scss"],
})
export class CheckBoxComponent {
  public field: FieldsConfig;
  public group: FormGroup;
}
