import {AfterViewInit, Component, OnDestroy, ViewChild, ViewContainerRef} from "@angular/core";
import {FormControl, FormGroup, FormGroupDirective, NgForm} from "@angular/forms";
import {ErrorStateMatcher} from "@angular/material/core";
import * as textMask from "vanilla-text-mask/dist/vanillaTextMask";
import {FieldsConfig} from "../../field.interface";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  public isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: "app-input-mail",
  templateUrl: "./input-mail.component.html",
  styleUrls: ["./input-mail.component.scss"],
})
export class InputMailComponent {
  public field: FieldsConfig;
  public group: FormGroup;

  public matcher = new MyErrorStateMatcher();

}
