import {Component} from "@angular/core";
import { FormGroup} from "@angular/forms";
import {FieldsConfig} from "../../field.interface";

@Component({
  selector: "app-input-string-long",
  templateUrl: "./input-string-long.component.html",
  styleUrls: ["./input-string-long.component.scss"],
})
export class InputStringLongComponent {
  public field: FieldsConfig;
  public group: FormGroup;
}
