import {Component, OnInit} from '@angular/core';
import { FormGroup} from "@angular/forms";
import {FieldsConfig} from "../../field.interface";

@Component({
  selector: "input-select",
  templateUrl: "./input-select.component.html",
  styleUrls: ["./input-select.component.scss"],
})
export class InputSelectComponent {
  public field: FieldsConfig;
  public group: FormGroup;
}
