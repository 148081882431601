import {AfterViewInit, Component, OnDestroy, ViewChild, ViewContainerRef} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {OwlDateTimeIntl} from "ng-pick-datetime";
import * as textMask from "vanilla-text-mask/dist/vanillaTextMask.js";
import {FieldsConfig} from "../../field.interface";

@Component({
  selector: "app-input-datetime",
  templateUrl: "./input-datetime.component.html",
  styleUrls: ["./input-datetime.component.scss"],
})
export class InputDateTimeComponent extends OwlDateTimeIntl implements AfterViewInit, OnDestroy {

  public cancelBtnLabel = "Quitter";
  public setBtnLabel = "Valider";
  public field: FieldsConfig;
  public group: FormGroup;
  // mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, ":", /\d/, /\d/]; // dd/mm/yyyy HH:mm
  // maskedInputController;

  @ViewChild("input", {static: false, read: ViewContainerRef }) public input;

  constructor() {
    super();
  }

  public ngAfterViewInit(): void {
    /*setTimeout(() => {
      this.maskedInputController = textMask.maskInput({
        inputElement: this.input.element.nativeElement,
        mask: this.mask
      });
    });*/
  }

  public ngOnDestroy() {
    // this.maskedInputController.destroy();
  }

}
