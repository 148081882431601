import {Component, Inject, OnInit} from '@angular/core';
import {VerificationService} from './services/verification.service';
import {DOCUMENT} from '@angular/common';
import { environment } from './../environments/environment';
import {dom} from "@fortawesome/fontawesome-svg-core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [],
})
export class AppComponent implements OnInit {

  isAuthorized = environment.isAuthorized;
  isValidation = false;

  constructor(private VerificationService: VerificationService, @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit(): void {
    this.VerificationService.editionMode = false;
    var bool = false;
    if (window.self !== window.top) {
      bool = true;
    }

    var domainName = document.referrer.split('/')[2];

    var currentidForm;
    if (window.location.href.includes(environment.baseValidation)) {
      currentidForm = window.location.href.replace(environment.baseValidation, '');
      this.isValidation = true;
    } else {
      currentidForm = window.location.href.replace(environment.base, '').split('?')[0];
      this.isValidation = false;
    }

    var data = {
      idForm: currentidForm,
      domainName: domainName || null,
      inIframe: bool,
      isValidation: this.isValidation
    };

    if (data['domainName'] && data['domainName'].includes(environment.url)) {
      this.isAuthorized = true;
      this.VerificationService.editionMode = true;
    } else {
      this.VerificationService.verif(data).subscribe(
        (res) => {
          if (res['code'] == 200) {
            if (!data['inIframe']) {
              let head = document.getElementsByTagName('head')[0];
              let script = document.createElement('script');
              script.type = 'text/javascript';
              script.src = environment.eliott;
              head.appendChild(script);
            }
            this.isAuthorized = true;
          }
        },
        (err) => {
          console.log('Error occurred:', err);
        },
      );
    }
  }
}
