import {formatDate} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {
  AfterViewChecked, AfterViewInit,
  ChangeDetectorRef,
  Component, ElementRef,
  forwardRef,
  OnDestroy,
  OnInit, ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {FieldsConfig, FormConfig} from '../../field.interface';
import {LoaderInterceptor} from '../../interceptors/loader.interceptor';
import {AutoCompletionService} from '../../services/auto-completion.service';
import {FormService} from '../../services/form.service';
import {LoaderService} from '../../services/loader.service';
import {InputDateTimeComponent} from '../input-datetime/input-datetime.component';
import {AutoCompletionComponent} from '../auto-completion/auto-completion.component';
import {VerificationService} from '../../services/verification.service';
import {MatDialog} from '@angular/material';
import {EditionValidation} from '../edition-validation/edition.component';

export interface Color {
  name: string;
  hex: string;
}

@Component({
  exportAs: 'appform',
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [
    FormService,
    LoaderService,
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDateTimeComponent),
      multi: true,
    },
  ],

})
export class FormComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit, AfterViewChecked {

  public FormConfig: FormConfig;
  public FieldsConfig: FieldsConfig[] = [];
  public isDataAvailable = false;
  public fields: FieldsConfig[];
  public formParent: FormGroup;
  public idForm: string;
  public randomId: string;

  public bg: string;
  public text: string;
  public texterror: string;

  public bgColorPalette: Color[] = [];
  public textColorPalette: Color[] = [];
  public textErrorColorPalette: Color[] = [];

  public idFieldAutoCompletion = 1;
  public idFieldTextCourt = 2;
  public idFieldTextLong = 3;
  public idFieldDate = 4;
  public idFieldDatetime = 5;
  public idFieldNumber = 6;
  public idFieldCheckBox = 7;
  public idFieldRadioButton = 8;
  public idFieldMail = 9;
  public idFieldTelephone = 10;
  public idFieldNom = 11;
  public idFieldPrenom = 12;
  public idFieldFonction = 13;
  public idFieldListeDeroulante = 14;
  public idFieldMobile = 15;
  public useInIframe: boolean = false;
  public static ifFormRgpdType = 1;
  link: string = null;

  public captcha_auth = false;

  @ViewChild('recaptcha', {static: false }) recaptchaElement: ElementRef;

  constructor(private formBuilder: FormBuilder,
              private formService: FormService,
              private autoCompletionService: AutoCompletionService,
              private VerificationService: VerificationService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private ref: ChangeDetectorRef,
              private _snackBar: MatSnackBar,
              public dialog: MatDialog) {
    this.sanitizer = sanitizer;
  }

  public ngOnInit() {
    this.randomId = this.getParamValue('randomId');
    this.useInIframe = this.inIframe();
    this.idForm = this.router.url.replace('/formulaire/', '').split('?')[0];
    this.getFormData(this.idForm);
    this.ref.detectChanges();
  }

  rescaleCaptcha($event){
      if(typeof this.recaptchaElement != 'undefined'){
        let width = this.recaptchaElement.nativeElement.offsetWidth;
        let scale;
        if (width < 302) {
          scale = width / 302;
        } else{
          scale = 1.0;
        }
        this.recaptchaElement.nativeElement.setAttribute("style", "transform:scale(" + scale + ");-webkit-transform:scale(" + scale + ");transform-origin:0 0;-webkit-transform-origin:0 0;");
      }
  }

  getParamValue(paramName){
    var url = window.location.search.substring(1); //get rid of "?" in querystring
    var qArray = url.split('&'); //get key-value pairs
    for (var i = 0; i < qArray.length; i++)
    {
      var pArr = qArray[i].split('='); //split key and value
      if (pArr[0] == paramName)
        return pArr[1]; //return value
    }
  }

  renderReCaptch() {
    setTimeout(() => {
      window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
        'sitekey': '6LdoC8sUAAAAAN2gqGkB1q05mLkl4oJYWKIgtmKy',
        'callback': (response) => {
          this.formService.check_captcha({token:response}).subscribe((data) => {
              if(JSON.parse(data['body'])['success']){
                this.formParent.get('Captcha').setValue(true);
              }
          }, error => {},
            () => {
              this.formParent.updateValueAndValidity();
              if(this.formParent.status == 'VALID'){
                this.captcha_auth = true;
              }else{
                this.captcha_auth = false;
              }
            });
        }
      });
    }, 100);
  }

  addRecaptchaScript() {

    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
      this.rescaleCaptcha(null);
    }

    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptch(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));

  }

  public hexToRgb(hex) {
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  public luminance(r, g, b) {
    var a = [r, g, b].map(function (v) {
      v /= 255;
      return v <= 0.03928
        ? v / 12.92
        : Math.pow( (v + 0.055) / 1.055, 2.4 );
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  private inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  private getFontCSS(name) {
    this.link = `https://fonts.googleapis.com/css?family=${name.replace(' ', '+')}&display=swap`;
    return this.link;
  }

  private createControl() {
    const group = this.formBuilder.group({});
    if (this.fields != null) {
      this.fields.forEach((field) => {
        switch (field.idFieldType.toString()) {
          // Autocompletion
          case this.idFieldAutoCompletion.toString():
            if (field.fieldRequired) {
              const AC = this.formBuilder.control('', Validators.compose([Validators.required, this.ACValidator]));
              group.addControl(field.idFormField.toString(), AC);
            } else {
              const AC = this.formBuilder.control('', Validators.compose([this.ACValidator]));
              group.addControl(field.idFormField.toString(), AC);
            }
            break;

          // Text court
          case this.idFieldTextCourt.toString():
            if (field.fieldRequired) {
              const text = this.formBuilder.control('', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z\-'áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ\s]*$/)]));
              group.addControl(field.idFormField.toString(), text);
            } else {
              const text = this.formBuilder.control('', Validators.pattern(/^[a-zA-Z\-'áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ\s]*$/));
              group.addControl(field.idFormField.toString(), text);
            }
            break;

          // Nombre
          case this.idFieldNumber.toString():
            if (field.fieldRequired) {
              const text = this.formBuilder.control('', Validators.compose([Validators.required]));
              group.addControl(field.idFormField.toString(), text);
            } else {
              const text = this.formBuilder.control('');
              group.addControl(field.idFormField.toString(), text);
            }
            break;

          // Text long
          case this.idFieldTextLong.toString():
            if (field.fieldRequired) {
              const text = this.formBuilder.control('', Validators.compose([Validators.required]));
              group.addControl(field.idFormField.toString(), text);
            } else {
              const text = this.formBuilder.control('');
              group.addControl(field.idFormField.toString(), text);
            }
            break;

          // Nom
          case this.idFieldNom.toString():
            if (field.fieldRequired) {
              const text = this.formBuilder.control('', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z\-'áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ\s]*$/)]));
              group.addControl(field.idFormField.toString(), text);
            } else {
              const text = this.formBuilder.control('', Validators.pattern(/^[a-zA-Z\-'áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ\s]*$/));
              group.addControl(field.idFormField.toString(), text);
            }
            break;

          // Prenom
          case this.idFieldPrenom.toString():
            if (field.fieldRequired) {
              const text = this.formBuilder.control('', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z\-'áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ\s]*$/)]));
              group.addControl(field.idFormField.toString(), text);
            } else {
              const text = this.formBuilder.control('', Validators.pattern(/^[a-zA-Z\-'áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ\s]*$/));
              group.addControl(field.idFormField.toString(), text);
            }
            break;

          // Fonction
          case this.idFieldFonction.toString():
            if (field.fieldRequired) {
              const text = this.formBuilder.control('', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z\-'áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ\s]*$/)]));
              group.addControl(field.idFormField.toString(), text);
            } else {
              const text = this.formBuilder.control('', Validators.pattern(/^[a-zA-Z\-'áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ\s]*$/));
              group.addControl(field.idFormField.toString(), text);
            }
            break;

          // Mail
          case this.idFieldMail.toString():
            if (field.fieldRequired) {
              const mail = this.formBuilder.control('', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]));
              group.addControl(field.idFormField.toString(), mail);
            } else {
              const mail = this.formBuilder.control('', Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
              group.addControl(field.idFormField.toString(), mail);
            }
            break;

          // Telephone
          case this.idFieldTelephone.toString():
            if (field.fieldRequired) {
              const tel = this.formBuilder.control('', Validators.compose([Validators.required, Validators.pattern(/^[\s]*?(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}[\s]*?$/)]));
              group.addControl(field.idFormField.toString(), tel);
            } else {
              const tel = this.formBuilder.control('', Validators.pattern(/^[\s]*?(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}[\s]*?$/));
              group.addControl(field.idFormField.toString(), tel);
            }
            break;

          // Mobile
          case this.idFieldMobile.toString():
            if (field.fieldRequired) {
              const tel = this.formBuilder.control('', Validators.compose([Validators.required, Validators.pattern(/^[\s]*?(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}[\s]*?$/)]));
              group.addControl(field.idFormField.toString(), tel);
            } else {
              const tel = this.formBuilder.control('', Validators.pattern(/^[\s]*?(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}[\s]*?$/));
              group.addControl(field.idFormField.toString(), tel);
            }
            break;

          // Date
          case this.idFieldDate.toString():
            if (field.fieldRequired) {
              const date = this.formBuilder.control('', Validators.compose([Validators.required, this.DATEValidator]));
              group.addControl(field.idFormField.toString(), date);
            } else {
              const date = this.formBuilder.control('', Validators.compose([this.DATEValidator]));
              group.addControl(field.idFormField.toString(), date);
            }
            break;

          // Datetime
          case this.idFieldDatetime.toString():
            if (field.fieldRequired) {
              const datetime = this.formBuilder.control('', Validators.compose([Validators.required, this.DATETIMEValidator]));
              group.addControl(field.idFormField.toString(), datetime);
            } else {
              const datetime = this.formBuilder.control('', Validators.compose([this.DATETIMEValidator]));
              group.addControl(field.idFormField.toString(), datetime);
            }
            break;

          // Radio
          case this.idFieldRadioButton.toString():
            if (group.get(field.idFormField.toString()) == null) {
              // @ts-ignore
              const control = this.formBuilder.control(field.fieldValue[0].value);
              group.addControl(field.idFormField.toString(), control);
            }
            break;
          // Checkbox
          case this.idFieldCheckBox.toString():
            if (field.fieldRequired) {
              const control = this.formBuilder.control(false, Validators.requiredTrue);
              group.addControl(field.idFormField.toString(), control);
            } else {
              const control = this.formBuilder.control(false);
              group.addControl(field.idFormField.toString(), control);
            }
            break;

          // Liste déroulante
          case this.idFieldListeDeroulante.toString():
            if (field.fieldRequired) {
              const control = this.formBuilder.control('', Validators.required);
              group.addControl(field.idFormField.toString(), control);
            } else {
              const control = this.formBuilder.control('');
              group.addControl(field.idFormField.toString(), control);
            }
            break;

          default:
            if (field.fieldRequired) {
              const control = this.formBuilder.control('', Validators.compose([Validators.required]));
              group.addControl(field.idFormField.toString(), control);
            } else {
              const control = this.formBuilder.control('');
              group.addControl(field.idFormField.toString(), control);
            }
            break;
        }
      });

      if(this.FormConfig.formreCaptcha == 1){
        const reCaptcha = this.formBuilder.control(false, Validators.requiredTrue);
        group.addControl('Captcha', reCaptcha);
      }

      if(this.FormConfig.formRgpdType != null){
        if (this.FormConfig.formRgpdType != 1 && this.FormConfig.formRgpdType != 2) {
          const rgpdCtrl = this.formBuilder.control(false, Validators.requiredTrue);
          group.addControl('RGPD ' + this.FormConfig.formRgpdType, rgpdCtrl);
        }
      }

    }
    return group;
  }

  public onSubmit() {
    if (this.Check() && !this.VerificationService.editionMode) {

      /*Objet retourné*/
      const result = {};

      result['id'] = this.FormConfig.idForm;
      result['fields'] = [];
      result['idAccount'] = this.FormConfig.idAccount;
      result['idFormRgpdType'] = this.FormConfig.formRgpdType;
      result['randomId'] = this.randomId;
      result['token'] = AutoCompletionComponent.token;
      this.fields.forEach((field) => {
        if (field.idFieldType == 7) {
          if (this.formParent.get(field.idFormField.toString()).value == false) {
            result['fields'].push({
              key: field.idFormField,
              fieldType: field.idFieldType,
              value: false,
            });
          } else {
            result['fields'].push({
              key: field.idFormField,
              fieldType: field.idFieldType,
              value: true,
            });
          }
        } else if (field.idFieldType == this.idFieldDate) {
          if (this.formParent.get(field.idFormField.toString()).value != '' && this.formParent.get(field.idFormField.toString()).value != null) {
            result['fields'].push({
              key: field.idFormField,
              fieldType: field.idFieldType,
              value: formatDate(this.formParent.get(field.idFormField.toString()).value, 'yyyy-MM-dd', 'en-US'),
            });
          } else {
            result['fields'].push({
              key: field.idFormField,
              fieldType: field.idFieldType,
              value: null,
            });
          }

        } else if (field.idFieldType == this.idFieldDatetime) {
          if (this.formParent.get(field.idFormField.toString()).value != '' && this.formParent.get(field.idFormField.toString()).value != null) {
            result['fields'].push({
              key: field.idFormField,
              fieldType: field.idFieldType,
              value: formatDate(this.formParent.get(field.idFormField.toString()).value, 'yyyy-MM-dd HH:mm', 'en-US'),
            });
          } else {
            result['fields'].push({
              key: field.idFormField,
              fieldType: field.idFieldType,
              value: null,
            });
          }
        } else if (field.idFieldType == this.idFieldAutoCompletion) {
          result['fields'].push({
            key: field.idFormField,
            fieldType: field.idFieldType,
            value: this.formParent.get(field.idFormField.toString()).value,
          });
        } else {
          if (this.formParent.get(field.idFormField.toString()).value != '') {
            result['fields'].push({
              key: field.idFormField,
              fieldType: field.idFieldType,
              value: this.formParent.get(field.idFormField.toString()).value,
            });
          } else {
            result['fields'].push({
              key: field.idFormField,
              fieldType: field.idFieldType,
              value: null,
            });
          }
        }
      });

      try {
        if (this.FormConfig.formValidationLink == 1) {
          this.formService.insert(result, this.FormConfig.formValidationLinkUrl, this.idForm);
        } else {
          this.formService.insert(result, null, this.idForm);
        }
      } catch (err) {}
    }else {
      this.openDialog();
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(EditionValidation, {
      width: 'auto'
    });
  }

  private loadCSS(data: any[]) {
    document.documentElement.style.setProperty('--bgColor', data[0].formBackgroundColor);
    document.documentElement.style.setProperty('--firstFont', data[0].formFirstFont);
    document.documentElement.style.setProperty('--secondFont', data[0].formSecondFont);
    document.documentElement.style.setProperty('--firstColor', data[0].formFirstColor);
    document.documentElement.style.setProperty('--SecondColor', data[0].formSecondColor);
    document.documentElement.style.setProperty('--border-radius', data[0].formBorderRadius == 0 ? '0px' : '5px');
    document.documentElement.style.setProperty('--validationColor', data[0].formValidationColor);
    document.documentElement.style.setProperty('--buttonTextColor', data[0].formValidationColorText);
    document.documentElement.style.setProperty('--ContourColor', data[0].formContourColor);
    document.documentElement.style.setProperty('--spacer-default', '35px');
  }

  private getFormData(idForm) {
    this.formService.getFormData(idForm,{referer: document.referrer}).subscribe((data: any[]) => {
      let formFirstFont;
      formFirstFont = document.createElement('link');
      formFirstFont.setAttribute('rel', 'stylesheet');
      formFirstFont.setAttribute('href', this.getFontCSS(data[0].formFirstFont));
      document.head.appendChild(formFirstFont);
      let formSecondFont;
      formSecondFont = document.createElement('link');
      formSecondFont.setAttribute('rel', 'stylesheet');
      formSecondFont.setAttribute('href', this.getFontCSS(data[0].formSecondFont));
      document.head.appendChild(formSecondFont);
      this.loadCSS(data);
      data.forEach((field, index) => {
        if (index > 0) {
            if (field.fieldIsActive) {
              if (field.idFieldType == this.idFieldListeDeroulante) {
                this.FieldsConfig.push({
                  fieldName: field.fieldName,
                  fieldOrder: field.fieldOrder,
                  fieldTypeDescription: field.fieldTypeDescription,
                  idFieldType: field.idFieldType,
                  idFormField: field.idFormField,
                  fieldRequired: field.fieldRequired == 1 ? true : false,
                  fieldIsActive: field.fieldIsActive,
                  choice: field.choice,
                });
              } else {
                this.FieldsConfig.push({
                  fieldName: field.fieldName,
                  fieldOrder: field.fieldOrder,
                  fieldTypeDescription: field.fieldTypeDescription,
                  idFieldType: field.idFieldType,
                  idFormField: field.idFormField,
                  fieldRequired: field.fieldRequired == 1 ? true : false,
                  fieldIsActive: field.fieldIsActive,
                });
              }
            }
          }
      });
      this.FormConfig = {
        idForm: data[0].idForm,
        formTitle: data[0].formTitle,
        formButtonTitle: data[0].formButtonTitle,
        formBackgroundColor: data[0].formBackgroundColor,
        formFirstColor: data[0].formFirstColor,
        formSecondColor: data[0].formSecondColor,
        formFirstFont: data[0].formFirstFont,
        formSecondFont: data[0].formSecondFont,
        formBorderRadius: data[0].formBorderRadius,
        formRgpdType: data[0].formRgpdType,
        formRgpdText: data[0].formRgpdText,
        rgpdTypeAcceptationDesc: data[0].rgpdTypeAcceptationDesc != null ? data[0].rgpdTypeAcceptationDesc.replace('%companyName%',data[0].CompanyName) : data[0].rgpdTypeAcceptationDesc,
        formValidationColor: data[0].formValidationColor,
        formValidationLink: data[0].formValidationLink,
        formValidationLinkUrl: data[0].formValidationLinkUrl,
        formValidationText: data[0].formValidationText,
        formValidationColorText: data[0].formValidationColorText,
        formContourColor: data[0].formContourColor,
        formIsActive: data[0].formIsActive,
        idAccount: data[0].idAccount,
        formreCaptcha: data[0].formreCaptcha,
        fields: this.FieldsConfig,
      };
      FormComponent.ifFormRgpdType = this.FormConfig.formRgpdType;
      this.bg = data[0].formBackgroundColor;
      this.text = data[0].formSecondColor;
      this.texterror = '#FF0000';
    }, (err) => {}, () => {
      this.isDataAvailable = true;
      this.fields = this.FormConfig.fields;
      this.formParent = this.createControl();
      if(this.FormConfig.formreCaptcha == 1)
      {
        this.addRecaptchaScript();
      }
      this.setBgColor();
      this.setTextColor();
      this.setTextErrorColor();
      if(this.pickTextColorBasedOnBgColorAdvanced(this.FormConfig.formBackgroundColor) == 'white'){
        //bg black => color white
        var choice = [400,300,200,100,-50,-100,-200,-300,-400,-500,-600,-700];
        this.checkContrast(choice);
        this.checkContrastError(choice);
        document.documentElement.style.setProperty('--fieldbgColor', document.documentElement.style.getPropertyValue('--theme-bg-300'));
        document.documentElement.style.setProperty('--ac', document.documentElement.style.getPropertyValue('--theme-bg-200'));
      }else{
        //bg white => color black
        var choice = [1700,1600,1500,1400,1300,1200,1100,1000,900,800,700,600];
        var choiceError = [600,700,800,900,1000,1100,1200,1300,1400,1500,1600,1700];
        this.checkContrast(choice);
        this.checkContrastError(choiceError);
        document.documentElement.style.setProperty('--fieldbgColor', document.documentElement.style.getPropertyValue('--theme-bg-800'));
        document.documentElement.style.setProperty('--ac', document.documentElement.style.getPropertyValue('--theme-bg-900'));
      }
      //57.65
    });
  }

  public checkContrast(choice){
    var luminanceBg = this.luminance(this.hexToRgb(this.FormConfig.formBackgroundColor).r, this.hexToRgb(this.FormConfig.formBackgroundColor).g, this.hexToRgb(this.FormConfig.formBackgroundColor).b);
    for(var i = 0;i<choice.length;i++){
      var luminancetext = this.luminance(this.hexToRgb(document.documentElement.style.getPropertyValue('--theme-bg-' + choice[i])).r,
        this.hexToRgb(document.documentElement.style.getPropertyValue('--theme-bg-' + choice[i])).g,
        this.hexToRgb(document.documentElement.style.getPropertyValue('--theme-bg-' + choice[i])).b);
      const ratio = luminanceBg > luminancetext
        ? ((luminancetext + 0.05) / (luminanceBg + 0.05))
        : ((luminanceBg + 0.05) / (luminancetext + 0.05));
      if(ratio < 1/4.5){
        document.documentElement.style.setProperty('--PlaceHolder', document.documentElement.style.getPropertyValue('--theme-bg-' + choice[i]));
        return;
      }
    }
  }

  public checkContrastError(choice){
    var luminanceBg = this.luminance(this.hexToRgb(this.FormConfig.formBackgroundColor).r, this.hexToRgb(this.FormConfig.formBackgroundColor).g, this.hexToRgb(this.FormConfig.formBackgroundColor).b);
    for(var i = 0;i<choice.length;i++){
      var luminancetext = this.luminance(this.hexToRgb(document.documentElement.style.getPropertyValue('--theme-texterror-' + choice[i])).r,
        this.hexToRgb(document.documentElement.style.getPropertyValue('--theme-texterror-' + choice[i])).g,
        this.hexToRgb(document.documentElement.style.getPropertyValue('--theme-texterror-' + choice[i])).b);
      const ratio = luminanceBg > luminancetext
        ? ((luminancetext + 0.05) / (luminanceBg + 0.05))
        : ((luminanceBg + 0.05) / (luminancetext + 0.05));
      if(ratio < 1/4.5){
        document.documentElement.style.setProperty('--TextError', document.documentElement.style.getPropertyValue('--theme-texterror-' + choice[i]));
        return;
      }
    }
  }

  public pickTextColorBasedOnBgColorAdvanced(bgColor) {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
    return (L > 0.179) ? 'black': 'white' ;
  }

  private LightenDarkenColor(col, amt) {

    // Suppression du #
    col = col.slice(1);

    const num = parseInt(col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }

    let g = ((num >> 8) & 0x00FF) + amt;

    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }

    let b = (num & 0x0000FF) + amt;

    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }

    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  private setBgColor() {
    this.bgColorPalette = this.computeColors(this.bg);
    for (const color of this.bgColorPalette) {
      const key = `--theme-bg-${color.name}`;
      const value = color.hex;
      document.documentElement.style.setProperty(key, value);
    }
  }

  private setTextColor() {
    this.textColorPalette = this.computeColors(this.text);
    for (const color of this.textColorPalette) {
      const key = `--theme-text-${color.name}`;
      const value = color.hex;
      document.documentElement.style.setProperty(key, value);
    }
  }

  private setTextErrorColor() {
    this.textErrorColorPalette = this.computeColors(this.texterror);
    for (const color of this.textErrorColorPalette) {
      const key = `--theme-texterror-${color.name}`;
      const value = color.hex;
      document.documentElement.style.setProperty(key, value);
    }
  }

  private computeColors(hex: string): Color[] {
    return [
      this.getColorObject(this.LightenDarkenColor(hex, 255), '-700'),
      this.getColorObject(this.LightenDarkenColor(hex, 220), '-600'),
      this.getColorObject(this.LightenDarkenColor(hex, 200), '-500'),
      this.getColorObject(this.LightenDarkenColor(hex, 180), '-400'),
      this.getColorObject(this.LightenDarkenColor(hex, 160), '-300'),
      this.getColorObject(this.LightenDarkenColor(hex, 140), '-200'),
      this.getColorObject(this.LightenDarkenColor(hex, 90), '-100'),
      this.getColorObject(this.LightenDarkenColor(hex, 70), '-50'),
      this.getColorObject(this.LightenDarkenColor(hex, 52), '50'),
      this.getColorObject(this.LightenDarkenColor(hex, 37), '100'),
      this.getColorObject(this.LightenDarkenColor(hex, 26), '200'),
      this.getColorObject(this.LightenDarkenColor(hex, 12), '300'),
      this.getColorObject(this.LightenDarkenColor(hex, 6), '400'),
      this.getColorObject(this.LightenDarkenColor(hex, 0), '500'),
      this.getColorObject(this.LightenDarkenColor(hex, -12), '600'),
      this.getColorObject(this.LightenDarkenColor(hex, -18), '700'),
      this.getColorObject(this.LightenDarkenColor(hex, -24), '800'),
      this.getColorObject(this.LightenDarkenColor(hex, -52), '900'),
      this.getColorObject(this.LightenDarkenColor(hex, -70), '1000'),
      this.getColorObject(this.LightenDarkenColor(hex, -90), '1100'),
      this.getColorObject(this.LightenDarkenColor(hex, -140), '1200'),
      this.getColorObject(this.LightenDarkenColor(hex, -160), '1300'),
      this.getColorObject(this.LightenDarkenColor(hex, -180), '1400'),
      this.getColorObject(this.LightenDarkenColor(hex, -200), '1500'),
      this.getColorObject(this.LightenDarkenColor(hex, -220), '1600'),
      this.getColorObject(this.LightenDarkenColor(hex, -255), '1700'),
    ];
  }

  private getColorObject(value, name): Color {
    return {
      name,
      hex: value,
    };
  }

  public Check() {
    let retour;
    if (this.formParent.valid || this.VerificationService.editionMode) {
      retour = true;
    } else {
      retour = false;
    }
    return retour;
  }

  private ACValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (typeof control.value != 'object') {
      return {ac: true};
    }
    return null;
  }

  private DATEValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value != null) {
      if (control.value._i != undefined) {
        if (typeof control.value._i != 'object') {
          let value = control.value._i;
          value = value.trim();
          const regexp: RegExp = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
          if (regexp.test(value)) {
          } else {
            return {date: true};
          }
        }
      }
    }
    return null;
  }

  private DATETIMEValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value != null) {
      if (control.value._i != undefined) {
        if (typeof control.value._i != 'object') {
          let value = control.value._i;
          value = value.trim();
          const regexp: RegExp = /^([1-9]|([012][0-9])|(3[01]))\/([0]{0,1}[1-9]|1[012])\/\d\d\d\d\s([0-1]?[0-9]|2?[0-3]):([0-5]\d)$/;
          if (regexp.test(value)) {
          } else {
            return {date: true};
          }
        }
      }
    }
    return null;
  }

  public ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  public ngOnDestroy() {
    this.ref.detach();
  }

  getheight($event: number) {
    var count = 0;
    this.FormConfig.fields.forEach(function (){
      count++;
    });

    var heightwindows = window.innerHeight;
    var height = $event;
    var diff = height - heightwindows;

    if(diff > 0){
      document.documentElement.style.setProperty('--height-vh', '0px');
      document.documentElement.style.setProperty('--vh', '23px');
    }else{
      diff = Math.abs((30/100)*diff);
      document.documentElement.style.setProperty('--height-vh', diff + 'px');
      document.documentElement.style.setProperty('--vh', '40px');
    }
  }

  ngAfterViewInit(): void {
    this.rescaleCaptcha(null);
  }
}
