import {Component} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {FieldsConfig} from "../../field.interface";

@Component({
  selector: "app-radio-button",
  templateUrl: "./radio-button.component.html",
  styleUrls: ["./radio-button.component.scss"],
})
export class RadioButtonComponent {
  public field: FieldsConfig;
  public group: FormGroup;
}
